import request from '../js/request'
import * as qiniu from 'qiniu-js'


// export const getQiniuUpToken = async function(type) {
//   const url = '/system/qiniu_token/'
//   const query = { file_type: type }
//   return await request({
//     url: url,
//     method: 'get',
//     params: query
//   })
// }

/**
 * 文件上传到七牛
 * @param {*} file 上传的文件
 * @param {*} key 文件上传的key
 * @param {*} token 文件上传的token
 */
export const uploadFile = function(file, key, token, callback) {
  const observalbe = qiniu.upload(file, key, token)
  const observer = {
    next(res) {
    // 处理上传进度
    let percent = parseInt(res.total.percent)
    let uploadingText = '正在上传：' + percent + '%'
    $("#upload-percent").text(uploadingText)
    if(percent === 100) {
      $("#upload-percent").text('文件上传完毕')
    }
    },
    error(err) {
      console.log(err)
      return callback(err)
    },
    complete(res) {
      console.log('qiniud上传完毕')
      console.log(res)
      return callback(null)
    }
  }
  observalbe.subscribe(observer)
}
