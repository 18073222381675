import request from '../js/request'

export function getQiniuUpToken(type) {
  const params = { file_type: type }
  return request({
    url: '/system/qiniu_token/',
    method: 'get',
    params
  })
}

export function setQrcodeOptions(data) {
  return request({
    url: '/qrcode/base/',
    method: 'post',
    data: data
  })
}