import { Bar } from '../js/bar.es6'
const { Foo } = require('../js/foo.cjs')
import { getQiniuUpToken, setQrcodeOptions } from '../js/api.js'
import { uploadFile } from '../js/qiniu.js'
import html2canvas from 'html2canvas'
import FileSaver from 'file-saver'
require('normalize.css/normalize.css')
require('../css/main.css')
require('../css/index.scss')
require('../css/colorPicker.css')
require('../js/jquery.colorPicker.js')
var QRCode = require('qrcode')
var canvas = document.getElementById('qrcode-canvas')
var canvasToSave = document.getElementById('qrcode-save-canvas')
var qrCodeValue = ''
var frontColor = '#4A4A4A'
var backColor = '#FFFFFF'
var errorCorrectionLevel = 'H'
var textMaxSize = 200
var logoSize = 84
var logoSaveSize = 84
var logoShowSize = 84
var logoUrl = ''
var imgSize = 250
var margin = 2
var qrCodeType = 0
var bgTransparent = false
var options = {
  errorCorrectionLevel: errorCorrectionLevel,
  type: 'image/jpeg',
  quality: 0.3,
  margin: margin,
  width: 250,
  color: {
    dark: frontColor,
    light: backColor
  }
}
var saveImgOptions = {
  errorCorrectionLevel: errorCorrectionLevel,
  type: 'image/jpeg',
  quality: 0.3,
  margin: margin,
  width: imgSize,
  color: {
    dark: frontColor,
    light: backColor
  }
}
/**
 * 顶部菜单栏切换，编辑区切换
 */
function changeNav() {
  $(".nav-container ul li").click(function () {
    let index = $(this).index()
    console.log(index)
    $(this).addClass("nav-active").siblings().removeClass("nav-active")
    $(".editor-container .editor-wrapper").eq(index).show().siblings().hide()
    switch (index) {
      case 0: qrCodeType =0; break;
      case 1: qrCodeType = 1; break;
      case 2: qrCodeType = 5; break;
      case 3: qrCodeType = 4; break;
      case 4: qrCodeType = 2; break;
      case 5: qrCodeType = 3; break;
      default: qrCodeType = 0
    }
  })
}

/**
 * 二维码参数导航栏切换
 */
function changeQrcodeNav() {
  $(".options-container .options-nav-wrapper ul li").click(function () {
    let index = $(this).index()
    console.log(index)
    $(this).addClass("qrcode-active").siblings().removeClass("qrcode-active")
    $(".options-container .options-wrapper .options-box").eq(index).show().siblings().hide()
  })
}

/**
 * 二维码基本设置
 */
function changeBasicSetting() {
  $('#error-rate').change(function () {
    let errRate = $('#error-rate').val()
    options.errorCorrectionLevel = errRate
    saveImgOptions.errorCorrectionLevel = errRate
    switch (errRate) {
      case 'H': logoSize = 84; break;
      case 'Q': logoSize = 80; break;
      case 'M': logoSize = 60; break;
      case 'L': logoSize = 40; break;
      default: logoSize = 84
    }
    logoShowSize = logoSize - (margin - 2) * 5
    if (imgSize > 250) {
      logoSaveSize = logoSize + (imgSize / 10) - (margin - 2) * 5
    } else if (imgSize < 250) {
      logoSaveSize = logoSize - 30 - (margin - 2) * 5
    }
    $('#logo-img-box').css({ 'width': logoShowSize, 'height': logoShowSize })
    $('#logo-img-save-box').css({ 'width': logoSaveSize, 'height': logoSaveSize })
    createQR(qrCodeValue)
  })
  $('#margin-options').change(function () {
    options.margin = $('#margin-options').val()
    saveImgOptions.margin = $('#margin-options').val()
    margin = $('#margin-options').val()
    logoShowSize = logoSize - (margin - 2) * 5
    if (imgSize > 250) {
      logoSaveSize = logoSize + (imgSize / 10) - (margin - 2) * 5
    } else if (imgSize < 250) {
      logoSaveSize = logoSize - 30 - (margin - 2) * 5
    } else {
      logoSaveSize = logoSize - (margin - 2) * 5
    }
    $('#logo-img-box').css({ 'width': logoShowSize, 'height': logoShowSize })
    $('#logo-img-save-box').css({ 'width': logoSaveSize, 'height': logoSaveSize })
    createQR(qrCodeValue)
  })
  $('#qrcode-img-size').change(function () {
    imgSize = $('#qrcode-img-size').val()
    saveImgOptions.width = imgSize
    if (imgSize > 250) {
      logoSaveSize = logoSize + (imgSize / 10) - (margin - 2) * 5
    } else if (imgSize < 250) {
      logoSaveSize = logoSize - 30 - (margin - 2) * 5
    } else {
      logoSaveSize = logoSize - (margin - 2) * 5
    }
    $('#qrcode-save-box').css({ 'width': imgSize, 'height': imgSize })
    $('.qrcode-hide-wrapper').css({ 'width': imgSize, 'height': imgSize })
    $('#logo-img-save-box').css({ 'width': logoSaveSize, 'height': logoSaveSize })
    createQR(qrCodeValue)
  })
}

/**
 * 二维码更改前景和背景颜色,透明背景
 */
function pickColor() {
  $('#front-color').colorPicker({
    pickerDefault: "4A4A4A", onColorChange: function (id, newValue) {
      console.log("ID: " + id + " has been changed to " + newValue)
      options.color.dark = $('#front-color').val()
      saveImgOptions.color.dark = $('#front-color').val()
      createQR(qrCodeValue)
    }
  })
  $('#back-color').colorPicker({
    pickerDefault: "FFFFFF", onColorChange: function (id, newValue) {
      console.log("ID: " + id + " has been changed to " + newValue)
      options.color.light = $('#back-color').val()
      saveImgOptions.color.light = $('#back-color').val()
      createQR(qrCodeValue)
    }
  })
  $('#transparent-color').change(function () {
    let checked = $('#transparent-color').is(':checked')
    bgTransparent = checked
    if (checked) {
      options.color.light = $('#transparent-color').val()
      saveImgOptions.color.light = $('#transparent-color').val()
      $('.color-options-wrapper .forbidden-icon').show()
      $('#back-color-wrapper .colorPicker-picker').hide()
      $('#qrcode-save-box').css('background-color', '#FFFFFF00')
      $('.blank-tips').css('visibility', 'hidden')
    } else {
      options.color.light = $('#back-color').val()
      saveImgOptions.color.light = $('#back-color').val()
      $('.color-options-wrapper .forbidden-icon').hide()
      $('#back-color-wrapper .colorPicker-picker').show()

    }
    createQR(qrCodeValue)
  })
}

/**
 * 清除设置
 */
function clearOptions() {
  $('#clear-options').click(function () {
    $('#error-rate').prop('value', 'H')
    $('#margin-options').prop('value', '2')
    $('#qrcode-img-size').prop('value', '250')
    $('#transparent-color').prop('checked', false)
    $('#front-color').val(frontColor)
    $('#back-color').val(backColor)
    $('#front-color, #back-color').change()
    $('.color-options-wrapper .forbidden-icon').hide()
    $('#back-color-wrapper .colorPicker-picker').show()
    $('#logo-img-wrapper').hide()
    $('#logo-img-save-wrapper').hide()
    $('#logo-img-choose').val('')
    $('#logo-img-box').css({ 'width': '164px', 'height': '164px' })
    $('#logo-img-save-box').css({ 'width': '164px', 'height': '164px' })
    imgSize = $('#qrcode-img-size').val()
    options.errorCorrectionLevel = $('#error-rate').val()
    options.margin = $('#margin-options').val()
    saveImgOptions.errorCorrectionLevel = $('#error-rate').val()
    saveImgOptions.margin = $('#margin-options').val()
    options.color.dark = $('#front-color').val()
    options.color.light = $('#back-color').val()
    saveImgOptions.color.dark = $('#front-color').val()
    saveImgOptions.color.light = $('#back-color').val()
    margin = 2
    logoUrl = ''
    createQR(qrCodeValue)
  })
}

/**
 * 获取七牛云token,并上传文件
 */
function uploadToQiniu(file) {
  getQiniuUpToken(0).then(function (res) {
    console.log(res)
    uploadFile(file, res.data.key, res.data.token, function (err) {
      if (err) {
        console.log('上传出错')
      }
      $('#file-upload-name').text(file.name)
      let url = res.data.url
      qrCodeValue = url
      createQR(qrCodeValue)
    })
  }).catch(function (err) {
    console.log(err)
  })
}

/**
 * 文件上传
 */
function upload_file() {
  $('#file-upload-input').change(function (e) {
    console.log(e.target.files[0])
    let file = e.target.files[0]
    let fileName = file.name
    let maxSize = 30 * 1024 * 1024 // 文件限制大小
    if (file.size > maxSize) {
      $('#file-upload-input').val('')
      return alert('文件大小不能超过30MB')
    }
    uploadToQiniu(file)
  })
}

/**
 * logo上传
 */
function upload_logo(file) {
  getQiniuUpToken(0).then(function (res) {
    console.log(res)
    uploadFile(file, res.data.key, res.data.token, function (err) {
      if (err) {
        console.log('上传出错')
      }
      logoUrl = res.data.url
    })
  }).catch(function (err) {
    console.log(err)
  })
}

/**
 * 捕获文本内容
 */
function getText() {
  $('#text-content').bind('input propertychange', 'textarea', function () {
    textSize()
    qrCodeValue = $('#text-content').val()
    createQR(qrCodeValue)
  })
}

/**
 * 文本字数限制
 */
function textSize() {
  let len = $('#text-content').val().length
  if (len > textMaxSize) {
    $('#text-content').val($('#text-content').val().substring(0, textMaxSize))
  }
  let num = textMaxSize - len
  if (num < 0) {
    num = 0
  }
  $("#word-size").text(num)
}

/**
 * 捕获URL内容
 */
function getURL() {
  $('#url-content').bind('input propertychange', function () {
    qrCodeValue = $('#url-content').val()
    createQR(qrCodeValue)
  })
}

/**
 * 添加多个电话号码
 */
function addTel() {
  $('#add-home-tel').click(function () {
    $('#add-home-tel-li').hide()
    $('#card-home-tel-li, #add-phone-li').show()
  })
  $('#add-phone').click(function () {
    $('#add-phone-li').hide()
    $('#card-phone-li, #add-phone').show()
  })
}

/**
 * 拼接名片的信息
 */
function getCardInfo() {
  let [name, workTel, homeTel, phone, email, qq, company, title, address, url] = [
    $('#card-name').val(), $('#card-work-tel').val(), $('#card-home-tel').val(), $('#card-phone').val(), $('#card-email').val(),
    $('#card-qq').val(), $('#card-company').val(), $('#card-title').val(), $('#card-address').val(), $('#card-url').val()
  ]
  let [begin, version, end] = ['BEGIN:VCARD\n', 'VERSION:3.0\n', 'END:VCARD\n']
  let cardInfo = begin + version + 'N:' + name + '; ;;;' + '\nTEL;WORK,VOICE:' + workTel + '\nTEL;HOME,VOICE:' + homeTel + '\nTEL;CELL,VOICE:' + phone + '\nEMAIL;INTERNET:' + email + '\nX-QQ:' + qq + '\nORG:' + company + '\nTITLE:' + title + '\nADR;WORK:;;' + address + ';;;;\nURL:' + url + '\n' + end
  qrCodeValue = cardInfo
}

/**
 * 捕获名片内容
 */
function getCard() {
  $('#card-name, #card-work-tel, #card-home-tel, #card-phone, #card-email, #card-qq, #card-company, #card-title, #card-address, #card-url').bind('input propertychange', function () {
    getCardInfo()
    console.log(qrCodeValue)
    createQR(qrCodeValue)
  })
}

/**
 * 捕获电话内容
 */
function getPhone() {
  $('#phone-content').bind('input propertychange', function () {
    let tel = $('#phone-content').val()
    let checked = checkTel(tel, '#phone-tips')
    if (checked) {
      tel = tel.split(' ').join('').split('-').join('')
      qrCodeValue = 'TEL://' + tel
      createQR(qrCodeValue)
    }
  })
}

/**
 * 校验电话号码
 * @param tel 电话号码
 * @param id 校验文字提示的id，'#...'
 */
function checkTel(tel, id) {
  // 去除电话号码空格和横线
  tel = tel.split(' ').join('').split('-').join('')
  let reg = /^((0\d{2,3}\d{7,8})|(1[34578]\d{9}))$/
  if (reg.test(tel)) {
    $(id).hide()
    return true
  } else {
    $(id).show()
    return false
  }
}

/**
* 捕获邮箱内容
*/
function getEmail() {
  $('#email-content').bind('input propertychange', function () {
    qrCodeValue = $('#email-content').val()
    createQR(qrCodeValue)
  })
}

/**
 * logo图片选择
 */
function getLogo() {
  $('#logo-img-choose').change(function (e) {
    let logoFile = e.target.files[0]
    $('#logo-img-box').attr('src', URL.createObjectURL($(this)[0].files[0]))
    $('#logo-img-wrapper').show()
    $('#logo-img-save-box').attr('src', URL.createObjectURL($(this)[0].files[0]))
    $('#logo-img-save-wrapper').show()
    $('#logo-preview').attr('src', URL.createObjectURL($(this)[0].files[0]))
    $('#logo-preview').show().css('display', 'inline-block')
    upload_logo(logoFile)
  })
}

/**
 * 二维码生成
 */
function createQR(value) {
  if (!value) {
    value = 'http://ddma.vip'
  }
  QRCode.toCanvas(canvas, value, options, function (error) {
    if (error) {
      console.error(error)
    }
    console.log('success!');
  })
  createQRImg(value)
}
/**
 * 保存为图片的二维码
 */
function createQRImg(value) {
  if (!value) {
    value = 'http://ddma.vip'
  }
  QRCode.toCanvas(canvasToSave, value, saveImgOptions, function (error) {
    if (error) {
      console.error(error)
    }
    console.log('success!');
  })
}

/**
 * base64转成file文件
 */
function base64ToBlob(urlData, type) {
  const arr = urlData.split(',')
  const mime = arr[0].match(/:(.*?);/)[1] || type
  // 去掉url的头，并转化为byte
  const bytes = window.atob(arr[1])
  // 处理异常,将ascii码小于0的转换为大于0
  const ab = new ArrayBuffer(bytes.length)
  // 生成视图（直接针对内存）：8位无符号整数，长度1个字节
  const ia = new Uint8Array(ab)
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i)
  }
  return new Blob([ab], {
    type: mime
  })
}

/**
 * html2canvas,生成base64文件，再转文件，最后保存
 */
function htmlToCanvas() {
  // 配置参数，useCORS跨域
  let options = { backgroundColor: null, scale: 1, useCORS: true, width: imgSize, height: imgSize }
  console.log(options)
  let qrcodeBox = document.getElementById("qrcode-save-box")
  html2canvas(qrcodeBox, options).then(function (canvas) {
    var dataUrl = canvas.toDataURL('png')
    var fileBlob = base64ToBlob(dataUrl, 'png')
    // 生成随机的六位数
    let num = "";
    for (let i = 0; i < 6; i++) {
      num += Math.floor(Math.random() * 10);
    }
    let date = new Date()
    let year = date.getFullYear()
    let month = date .getMonth() + 1
    month = month < 10 ? '0' + month : month
    let day = date.getDate()
    day = day < 10 ? '0' + day : day
    let imgName = 'DDM' + year + month + day + '-' + num + '.png'
    FileSaver.saveAs(fileBlob, imgName)
  })
}

/**
 * 保存图片
 */
function saveImg() {
  $('.save-qrcode-button').click(function () {
    htmlToCanvas()
    uploadQrcodeOptions()
  })
}

/**
 * 上传二维码配置
 */
function uploadQrcodeOptions() {
  let errorLevel
  let qrCodeMargin = margin * 2
  switch (errorCorrectionLevel) {
    case 'H': errorLevel = 30; break;
    case 'Q': errorLevel = 25; break;
    case 'M': errorLevel = 15; break;
    case 'L': errorLevel = 7; break;
  }
  let data = {
    text: qrCodeValue, error_level: errorLevel, margin: qrCodeMargin, size: imgSize, front_color: frontColor,
    background_color: backColor, bg_transparent: bgTransparent, logo: logoUrl, type: qrCodeType, source: 0
  }
  setQrcodeOptions(data).then(function (res) {
    console.log(res)
  }).catch(function (err) {
    console.log(err)
  })
}

$(document).ready(function () {
  changeNav()
  changeQrcodeNav()
  getText()
  getURL()
  getPhone()
  getEmail()
  getCard()
  pickColor()
  changeBasicSetting()
  clearOptions()
  upload_file()
  addTel()
  getLogo()
  saveImg()
})
